import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import {
  ManualRefundDto,
  ManualRefundFilterDto,
  ManualRefundUpdateDto,
  NewCashTransactionDto,
  PaymentConfigDto,
  PaymentInfoDto,
  TransactionBillingDataDto,
  TransactionDto,
  TransactionErrorInfoDto,
  TransactionInfoDto,
  TransactionsFilterDto,
} from './paymentService.dto';

class PaymentService {
  static BASE_URL = `${apiUrl}/api/payment`;

  fetchManualRefunds(filters: ManualRefundFilterDto): AxiosPromise<PageableType<ManualRefundDto>> {
    const url = `${PaymentService.BASE_URL}/admin/manual-refunds`;
    return apiService.get(url, { ...filters });
  }

  updateManualRefund(manualRefundId: number, data: ManualRefundUpdateDto) {
    const url = `${PaymentService.BASE_URL}/admin/manual-refunds/${manualRefundId}`;
    return apiService.patch(url, {}, {}, data);
  }

  createCashTransaction(data: NewCashTransactionDto): AxiosPromise<TransactionDto> {
    const url = `${PaymentService.BASE_URL}/admin/cash-transactions`;
    return apiService.post(url, {}, {}, data);
  }

  fetchTransactions(filters: TransactionsFilterDto): AxiosPromise<PageableType<TransactionInfoDto>> {
    const url = `${PaymentService.BASE_URL}/admin/transactions`;
    return apiService.get(url, { ...filters });
  }

  fetchTransactionErrors(transactionId: number): AxiosPromise<TransactionErrorInfoDto[]> {
    const url = `${PaymentService.BASE_URL}/admin/transactions/${transactionId}/errors`;
    return apiService.get(url);
  }

  fetchPayments(): AxiosPromise<PaymentInfoDto[]> {
    const url = `${PaymentService.BASE_URL}/admin/payments`;
    return apiService.get(url);
  }

  updatePayment(paymentId: number, enabled: boolean): AxiosPromise<PaymentInfoDto> {
    const url = `${PaymentService.BASE_URL}/admin/payments/${paymentId}`;
    return apiService.patch(url, {}, {}, { enabled });
  }

  fetchClientConfiguration(currencyCode: string): AxiosPromise<PaymentConfigDto> {
    const url = `${PaymentService.BASE_URL}/public/client-configuration`;
    return apiService.get(url, { currencyCode }, {});
  }

  fetchTransactionBillingData(transactionId: number): AxiosPromise<TransactionBillingDataDto> {
    const url = `${PaymentService.BASE_URL}/admin/transactions/${transactionId}/billing-data`;
    return apiService.get(url);
  }
}

const paymentService = new PaymentService();

export { paymentService, PaymentService };
